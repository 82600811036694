<div *ngIf="options.length" class="form-container__form-field flex flex-col w-full">
  <mat-label *ngIf="label !== null" class="label mb-1 form-container__form-field__label whitespace-nowrap" [ngClass]="{'text-danger mat-form-field-invalid' : _control.invalid && _control.touched, 'text-disabled' : _control.disabled}">{{ label || '' }}</mat-label>
  <mat-form-field class="flex w-full">
    <mat-select class="flex w-full"
                [placeholder]="placeholder"
                [formControl]="_control"
                [multiple]="multiple"
                (selectionChange)="onSelectionChange($event)"
                (opened)="focusOnInput()"
                (closed)="allowAnyValue || clearInput()"
    >
      <div class="m-2">
        <div class="box-search w-full">
          <mat-checkbox *ngIf="multiple" color="primary" class="box-select-all bg" (change)="toggleSelectAll($event)"></mat-checkbox>
          <input class="bg primary-font" #searchInput type="text" [ngClass]="{ 'pl-1': !multiple }" [value]="_control.value" (input)="filterItem(searchInput.value)" (keydown)="witchcraft($event)" [placeholder]="'input.search' | translate" data-test="autocompleteInput"/>
          <div class="box-search-icon bg" (click)="clearInput();_control.setValue(null)" data-test="autocompleteClearButton">
            <button mat-icon-button class="search-button">
              <mat-icon class="mat-24 mt-1" aria-label="Search icon">clear</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <mat-select-trigger> {{ displayString }} </mat-select-trigger>
      <mat-option
        *ngFor="let option of filteredOptions; trackBy: trackByFn"
        [disabled]="!!option.disabled"
        [value]="option.value"
      >{{ option.display }}
      </mat-option>
      <mat-option *ngIf="allowAnyValue && searchInput.value && !findInFiltered(searchInput.value.toUpperCase(), false)" [value]="searchInput.value">{{ searchInput.value }}</mat-option>
      <mat-option *ngIf="allowAnyValue && searchInput.value && !findInFiltered(searchInput.value.toUpperCase(), false) && _control.value && searchInput.value !== _control.value" [value]="_control.value">{{ _control.value }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
